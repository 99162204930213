<!--
 * @Author: egafrandika
 * @Date: 2024-03-07 09:28:39
 * @Last Modified by: egafrandika
 * @Last Modified time: 2024-10-28 16:24:28
-->

<template>
    <div class="warning-banner">
        <div
            class="wrapper-banner ec-container"
        >
            <div class="text">
                {{ $t('home.warningBanner.warn') }}
            </div>
            <span>{{ $t('home.warningBanner.desc') }}</span>
            <router-link :to="{name: 'faqBeware'}">
                <span class="decoration">{{ $t('home.warningBanner.moreInfo') }}</span>
            </router-link>
            <img
                src="./img/close-button.svg"
                @click="closeBanner"
            >
        </div>
    </div>
</template>

<script type="text/babel">
export default {
    name: 'WarningBanner',

    methods: {
        closeBanner() {
            this.$emit('bannerClosed');
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
div.warning-banner {
    .wrapper-banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 11.71rem;
        height: 0.84rem;
        position: relative;
        text-align: center;

        @media screen and (max-width: $screen-md) {
            padding: 0 0.1rem;
            width: 3.35rem;
        }

        .text {
            padding-bottom: 0.1rem;
            line-height: 0.2rem;
            color: #1db839;
            font-weight: 700;
            font-size: 0.24rem;
            font-family: Mozaic HUM;
            white-space: nowrap;

            @media screen and (max-width: $screen-md) {
                font-size: 0.14rem;
                line-height: 0.15rem;
                white-space: normal;
            }
        }

        img {
            position: absolute;
            width: auto;
            right: -0.5rem;
            top: 0.3rem;
            cursor: pointer;

            @media screen and(max-width: $screen-md) {
                top: 0;
                right: -0.1rem;
            }
        }

        span {
            color: #fff;
            font-weight: 400;
            font-family: Mozaic HUM;
            font-size: 0.16rem;
            line-height: 0.24rem;

            @media screen and(max-width: $screen-md) {
                width: 3.3rem;
                font-size: 0.12rem;
                justify-items: normal;
                line-height: 0.14rem;
            }
        }

        .decoration {
            text-decoration: underline;
        }
    }
}
</style>
