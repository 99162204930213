<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-6-25 20:27:30 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-10-12 14:08:12 -->

<template>
    <div class="index">
        <div v-if="isHome">
            <warning-banner
                :class="{'warning-banner-hide': isBannerHidden}"
                :style="{height: headerTop}"
                @bannerClosed="handleBannerClosed"
            />
        </div>
        <nav-header
            :blog-list="blogList"
            :style="{top: isHome ? headerTop : '0' }"
        />
        <router-view
            :blog-list="blogList"
            class="view"
            :style="{marginTop: isHome && !isBannerHidden ? '1.4rem' : '0'}"
        />
        <ec-footer />
        <img
            v-if="isShowComplaint"
            class="pendant"
            src="./complaint/img/pendant.png"
            @click="$router.push({name: 'complaint'})"
        >
    </div>
</template>

<script type="text/babel">
import EcFooter from 'easycash/common/component/ec-footer.vue';
import getContactMixin from 'easycash/common/mixin/get-contact';
import prefetch from 'easycash/common/mixin/server-prefetch';
import Config from 'easycash/common/resource/blog';

import NavHeader from './component/nav-header.vue';
import WarningBanner from './component/warning-banner.vue';

export default {
    name: 'Index',

    components: {
        NavHeader, EcFooter, WarningBanner
    },

    mixins: [getContactMixin(['IDN_YQD', 'IDN_FIN']), prefetch('homeBlog')],

    provide() {
        const {blogList} = this;

        return {blogList};
    },

    data() {
        return {
            scrollTop: 0,
            isBannerHidden: false
        };
    },

    computed: {
        blogList() {
            return this.homeBlog?.body?.blogList || [];
        },

        isShowComplaint() {
            const complaintPage = ['home', 'borrow', 'faq'];

            return complaintPage.some(item => this.$route.name.indexOf(item) >= 0);
        },

        isHome() {
            return this.$route.name === 'home';
        },

        headerTop() {
            return this.isBannerHidden ? '0' : '1.4rem';
        }
    },

    mounted() {
        window.onresize = () => {
            const deviceWidth = document.documentElement.clientWidth;
            const ratio = deviceWidth > 768 ? 144 : 37.5;
            const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            // eslint-disable-next-line no-bitwise
            const fz = deviceWidth <= 1440 ? ~~((width * 100000) / ratio) / 10000 : 100;
            document.getElementsByTagName('html')[0].style.cssText = `font-size: ${ fz }px`;
            // eslint-disable-next-line no-bitwise
            const realfz = ~~(+window.getComputedStyle(document.getElementsByTagName('html')[0]).fontSize.replace('px', '') * 10000) / 10000;
            const ua = window && window.navigator && window.navigator.userAgent;
            if (fz !== realfz && !/samsung/.test(ua)) {
                document.getElementsByTagName('html')[0].style.cssText = `font-size: ${ fz * (fz / realfz) }px`;
            }
        };

        // window.onbeforeunload = function () {
        //     document.documentElement.scrollTop = 0;
        //     document.body.scrollTop = 0;
        // };
        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        }
    },

    methods: {
        async initFetch() {
            const {data: {body}} = await Config.homeActive();

            return {body};
        },

        handleBannerClosed() {
            this.isBannerHidden = true;
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "~bootstrap/scss/bootstrap-grid";

html {
    height: auto;
    overflow: auto;
}
</style>

<style lang="scss" rel="stylesheet/scss">
    @font-face {
        font-family: "Mozaic HUM";
        font-weight: 300;
        src:
            url("~easycash/common/style/font-face/MozaicHUM-Light.woff2"),
            url("~easycash/common/style/font-face/MozaicHUM-Light.otf");
        font-display: swap;
    }

    @font-face {
        font-family: "Mozaic HUM";
        font-weight: 400;
        src:
            url("~easycash/common/style/font-face/MozaicHUM-Regular.woff2"),
            url("~easycash/common/style/font-face/MozaicHUM-Regular.otf");
        font-display: swap;
    }

    @font-face {
        font-family: "Mozaic HUM";
        font-weight: 500;
        src:
            url("~easycash/common/style/font-face/MozaicHUM-Medium.woff2"),
            url("~easycash/common/style/font-face/MozaicHUM-Medium.otf");
        font-display: swap;
    }

    @font-face {
        font-family: "Mozaic HUM";
        font-weight: 700;
        src:
            url("~easycash/common/style/font-face/MozaicHUM-Bold.woff2"),
            url("~easycash/common/style/font-face/MozaicHUM-Bold.otf");
        font-display: swap;
    }

    @font-face {
        font-family: "Mozaic HUM";
        font-weight: 900;
        src:
            url("~easycash/common/style/font-face/MozaicHUM-Black.woff2"),
            url("~easycash/common/style/font-face/MozaicHUM-Black.otf");
        font-display: swap;
    }

    @font-face {
        font-family: "Quicksand";
        font-weight: 400;
        src:
            url("~easycash/common/style/font-face/Quicksand-Regular.woff2"),
            url("~easycash/common/style/font-face/Quicksand-Regular.ttf");
        font-display: swap;
    }

    @font-face {
        font-family: "Quicksand";
        font-weight: 700;
        src:
            url("~easycash/common/style/font-face/Quicksand-Bold.woff2"),
            url("~easycash/common/style/font-face/Quicksand-Bold.ttf");
        font-display: swap;
    }

    .index {
        box-sizing: border-box;
        letter-spacing: 0;
    }

    .pendant {
        position: fixed;
        right: 0.3rem;
        bottom: 0.3rem;
        width: 1.3rem;
        height: 1.3rem;
        z-index: 999;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        @media screen and (max-width: $screen-md) {
            width: 0.8rem;
            height: 0.8rem;
            right: 0.2rem;
            bottom: 0.2rem;
        }
    }

    .warning-banner {
        display: flex;
        align-items: center;
        width: 100%;
        top: 0;
        position: fixed;
        z-index: 999;
        background-image: linear-gradient(90deg, rgba(63, 63, 63, 1), rgba(45, 45, 45, 1));

        &-hide {
            display: none;
        }
    }
</style>
