var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "warning-banner" }, [
    _c(
      "div",
      { staticClass: "wrapper-banner ec-container" },
      [
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("home.warningBanner.warn")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.$t("home.warningBanner.desc")))]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: { name: "faqBeware" } } }, [
          _c("span", { staticClass: "decoration" }, [
            _vm._v(_vm._s(_vm.$t("home.warningBanner.moreInfo"))),
          ]),
        ]),
        _vm._v(" "),
        _c("img", {
          attrs: { src: require("./img/close-button.svg") },
          on: { click: _vm.closeBanner },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }